<template>
  <div class="content">
    <breadcrumb
      :path="{'Yrityslainat': '/yrityslainat', 'Miten vertailla yrityslainoja': ''}"
    />
    <img style="width: 100%;" :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
    <h1>Miten yrityslainoja on järkevin ja helpoin vertailla?</h1>
    <p style="font-weight: 900;">
      Yrityslainojen korot ja kulut vaihtelevat eri lainanantajilla hyvinkin paljon. Myös erilaiset lainalimiitti -tuotteet ja joukkorahoituspalvelut sekoittavat helposti pään erilaisilla termeillään ja kuluillaan. Yrityslainojen kilpailutuksessa olennaista on saada eri tarjoukset mahdollisimman hyvin vertailtavaan muotoon.
    </p>
    <h4>
      Yrityslainojen korkojen ja kulujen ilmoittaminen
    </h4>
    <p>
      Suomen lainamarkkinoilla toimivien pankkien ja rahoituslaitosten tavassa ilmoittaa lainan korko ja kulut, on suuria eroja. Perinteisillä pankeilla korko on pääsääntöisesti aina ilmoitettu ja sen lisäksi on huomioitava muut kulut, joiden nimeämisessä on isoja eroja. Näitä kuluja, joita ei ilmoiteta koron sisällä ovat esimerkiksi avausmaksu, järjestelypalkkio, tilinhoitokulu tai käsittelykulu.
    </p>
    <p>
      Yrityslainojen markkinoinnin sääntely ei ole yhtä tiukkaa kuin kuluttajalainoilla ja erimerkiksi lainan todellista vuosikorkoa ei ole pakko ilmoittaa. Sääntelyn puuttuminen teki lainojen vertailtavuuden vaikeammaksi, mutta koventunut kilpailu yrityslainojen tarjoajien välillä ja lainan vertailu- ja kilpailutuspalvelujen tulo myös yrityslainamarkkinoille on muuttanut käytäntöjä ainakin isolla osalla lainantarjoajista.
    </p>
    <p>
      Yrityslainojen vertailtavuus on parantunut ja useat lainantarjoajat ilmoittavat suoraan lainatarjouksessaan mitkä ovat lainan kokonaiskulut annetun maksusuunnitelman mukaan.
    </p>
    <p>
      Esimerkiksi jos yritys hakee 10 000 euron lainan 12 kuukauden ajaksi, tarjouksessa kerrotaan lainan kuukausi erä (esim. 833 €) ja lainan kokonaiskustannus (esim. 11 992 €). Näin on helppo nähdä, mikä lainan hinta yritykselle on. (Tässä esimerkissä 1 992 €). Myös Rahalaitoksen kilpailutuspalvelussa kaikkien lainantarjoajien tarjouksista on laskettu kokonaiskustannus lainalle ja näin ollen lainojen vertailu on entistä helpompaa.
    </p>
    <h4>
      Vertailu yhdellä näytöllä on helppoa
    </h4>
    <p>
      Kun teet Rahalaitoksen kautta lainahakemuksen, se lähetetään useille yrityslainaa tarjoaville rahoitusyhtiöille. Kaikki saamasi tarjoukset kootaan yhteen selkeään näkymään Oma Rahalaitos -palveluun. Sinne kirjaudutaan omilla tunnuksilla ja vain sinä näet saamasi tarjoukset ja pystyt vertailemaan niitä helposti lainan kokonaiskulujen osalta. Oma Rahalaitos -palvelussa näet myös tarjotun lainasumman, kuukausierän ja laina-ajan. Näistä valitset sinulle sopivimman ja edullisimman lainan ja sen jälkeen pääset allekirjoittamaan kyseisen lainantarjoajan sopimuksen.
    </p>
    <span class="miniform-header"> Yrityslainan kilpailutus 250 000 € asti </span>
    <sliders go-to-url="/yrityslainat" :business-loan="true" :luottoraja="20000" :maksuaika="6" />
    <share text="Näin yrityslainoja on järkevin ja helpoin vertailla" />
  </div>
</template>

<script>

export default {
  layout: 'articles',
  name: 'YrityslainaVertailu',
  components: {
    share: () => import( '~/components/subpage/share' ),
    sliders: () => import( '~/components/subpage/sliders' ),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: 'Näin yrityslainoja on järkevin ja helpoin vertailla',
      meta: [
        { hid: 'description', name: 'description', content: 'Yrityslainojen vertailu on nyt helppoa.' }
      ]
    }
  }
}
</script>
