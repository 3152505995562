<template>
  <div class="content">
    <breadcrumb
      :path="{'Talous': '/talous', 'Mitä tekisit 200 eurolla': ''}"
    />
    <div class="subpage_floating">
      <img :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
      <sliders class="is-hidden-touch" />
    </div>
    <h1>Pienellä muutoksella voit säästää satoja euroja lainakuluissa</h1>
    <h5>Tiedätkö kuinka paljon korkoja ja kuluja maksat lainoistasi joka kuukausi?</h5>
    <p>
      Haluaisitko saada joka kuukausi esimerkiksi 200 euroa enemmän rahaa? Kukapa ei haluaisi. Oletko tullut ajatelleeksi, että saatat käyttää tuon summan huomaamattasi joka kuukausi maksamiesi luottokorttilaskujen ja kulutusluottojen korkoihin ja kuluihin? Yllättävän moni suomalainen maksaa pienlainoistaan suurempia korkoja, kuin heidän tarvitsisi.
    </p>
    <sliders class="is-hidden-desktop" />
    <br>
    <p>
      Kulutusluotot ja osamaksut ovat käteviä tapoja rahoittaa isompia hankintoja, joita ei pysty maksamaan kerralla. Mutta jos näitä pieniä luottoja alkaa kertyä useampia, kannattaa oma taloustilanne käydä läpi ja harkita <nuxt-link to="/yhdista-lainat"> lainojen yhdistämistä. </nuxt-link>
    </p>
    <p>
      Yhdistämisessä on kyse siitä, että useat pienet lainat maksetaan pois yhdellä isommalla. Lainojen yhdistäminen <nuxt-link to="/"> Rahalaitoksen </nuxt-link> kautta pienentää kuukausittaisia maksuja kolmella tavalla:
    </p>
    <h2>1. Säästät lainanhoitokuluissa</h2>
    <p>
      Ensinnäkin, yhdestä isommasta lainasta tarvitsee maksaa vain yhtä lainanhoitokulua, joka voi olla esimerkiksi viisi euroa kuukaudessa jokaista lainaa kohden. Tällä esimerkillä laskettuna neljän pienen luoton yhdistäminen yhdeksi isommaksi lainaksi säästäisi pelkästään lainanhoitokuluissa 15 euroa kuussa.
    </p>
    <h2>2. Saat pienemmän koron</h2>
    <p>
      Toiseksi, yhdessä suuremmassa lainassa on yleensä myös matalampi korko kuin pienissä. Näin ollen kuukausilyhennyksen ohessa maksettavan koron määrä on suhteessa pienempi, kun lainoja on vai yksi.
    </p>
    <h2>3. Löydät parhaan mahdollisen tarjouksen</h2>
    <p>
      Lisäksi, jos haluaa saada lainalle matalimman mahdollisen koron, kannattaa kilpailuttaa laina useammalla pankilla ja luotonmyöntäjällä. Eri pankkien koroissa on yllättävän suuret erot, ja siksi kilpailuttamisen tärkeys korostuu etenkin silloin, kun on tarkoitus säästää rahaa.
    </p>
    <p>
      Tämän kilpailutustyön Rahalaitos tekee puolestasi. Voit hakea sen kautta lainaa jopa 70 000 euroa.
    </p>
    <p>
      <nuxt-link to="/"> Tehtyäsi lainahakemuksen Rahalaitoksen sivuilla</nuxt-link>, kilpailuttaa palvelu sen jopa 25 pankilla. Sinun ei siis tarvitse tehdä muuta kuin käyttää muutama minuutti hakemuksen täyttämiseen.
    </p>
    <p>
      Mahdolliset tarjoukset saat Oma Rahalaitos -palveluun, ja voit tutustua niihin kaikissa rauhassa. Hakeminen ei vielä sido sinua nostamaan lainaa, vaan saat itse päättää, hyväksytkö jonkun tarjouksista.
    </p>
    <p>
      <nuxt-link to="/"> Katso, miten paljon voisit säästää joka kuukausi! Hae lainaa Rahalaitokselta – se ei maksa mitään, eikä sido sinua vielä mihinkään. </nuxt-link>
    </p>
    <share text="Mitä tekisit 200 eurolla?" />
  </div>
</template>

<script>
import sliders from '~/components/subpage/sliders'
import share from '~/components/subpage/share'

export default {
  layout: 'articles',
  name: 'MitaTekisit200Eurolla',
  components: {
    sliders,
    share,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: 'Mitä tekisit 200 eurolla? Näin voit säästää lainakuluissa',
      meta: [
        { hid: 'description', name: 'description', content: 'Tiedätkö kuinka paljon korkoja ja kuluja maksat lainoistasi joka kuukausi? Lue lisää lainakulujen säästämisestä.' }
      ]
    }
  }
}
</script>
