<template>
  <div>
    <main-hero
      title="Järjestelylaina auttaa säästämään lainakuluissa"
      subtitle="Järjestelylainalla saat lainakulut kuriin,
        kun yhdistät aiemmat lainasi yhdeksi"
      side-title="Hae järjestelylainaa ja vertaa!"
      :side-boxes="sideBoxes"
      survey-title="Käyttäjiemme arviot"
      :breadcrumb="{'Yhdistä lainat': '/yhdista-lainat', 'Järjestelylaina': ''}"
    />
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Järjestelylaina voi auttaa vaikeassa rahatilanteessa.
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2>
            Järjestelylainalla yhdistät monta lainaa yhdeksi!
          </h2>
          <p>
            Järjestelylainasta puhutaan usein tilanteessa, jossa ylivelkaantuminen on johtanut ongelmiin raha-asioissa. Käytännössä järjestelylainalla yhdistetään ongelmiin johtaneet luotot ja lainat yhdeksi lainaksi, jonka lyhennykset on mitoitettu hakijan rahatilanteeseen sopivaksi. Jos joudut kuukausittain maksamaan lainanlyhennyksiä useille eri lainanmyöntäjille, kannattaa harkita, kannattaisiko sinunkin ottaa järjestelylaina.
          </p>
          <p>
            Järjestely- eli <nuxt-link to="/yhdistelylaina">yhdistelylainaa</nuxt-link> voi hakea kuka tahansa, jolla on useita lainoja maksettavana samanaikaisesti. Kalliiden lainojen yhdistäminen on viisasta, sillä se auttaa säästämään ja helpottaa elämänhallintaa. Tässä koottuna hyötyjä, joita järjestelylainaa käyttämällä voi saavuttaa:
          </p>
          <ul>
            <li>
              Säästät rahaa, kun et joudu maksamaan tilin- ja lainanhoitokuluja sekä laskutuslisiä erikseen monesta eri lainasta ja luotosta. Jo pelkistä tilinhoitomaksuista saattaa kertyä kuukaudessa merkittävä summa, mikäli lainoja on useita.
            </li>
            <li>
              Järjestelylainalla voit löytää edullisemman koron ja pidemmän takaisinmaksuajan lainallesi. Tämä helpottaa selviämistä ylivelkaantumistilanteesta.
            </li>
            <li>
              Omasta taloudesta huolehtiminen ja raha-asioiden seuranta helpottuu, kun lyhennät vain yhtä järjestelylainaa.
            </li>
            <li>
              Kun maksettavana on vain yksi lasku, säästät myös aikaasi – et joudu muistamaan erikseen useita eräpäiviä ja käyttämään aikaa monen eri laskun maksamiseen.
            </li>
          </ul>
          <h2>
            Miten löytää paras järjestelylaina?
          </h2>
          <p>
            Yleensä kun asiakkailla on tarve yhdistää lainat, ei puhuta enää muutamista sadan euron pikavipeistä, vaan kyseessä voi olla useiden tuhansien eurojen edestä luottokorttilaskuja, osamaksuja sekä muita velkoja. Tätä varten voi olla tarve ottaa iso järjestelylaina.
          </p>
          <p>
            Kun kyseessä on iso järjestelylaina, on erityisen tärkeää vertailla järjestelylainoja löytääkseen niistä itselleen sopivimman. Joillekin paras järjestelylaina tarkoittaa pienintä korkoa tai edullisimpia lainakuluja. Toiselle parhaan järjestelylainan määrittää joustavat takaisinmaksuajat.
          </p>
          <p>
            Löytääksesi sopivin järjestelylaina, on suositeltavaa kilpailuttaa lainatarjoukset useilta pankeilta. Rahalaitoksen kautta tämä onnistuu todella sujuvasti, sillä kilpailutamme yhdellä hakemuksella useita lainanmyöntäjiä ja esittelemme sinulle jokaisen saamasi tarjouksen perinpohjaisesti.
          </p>
          <p>
            Voit palvelussamme vertailla rauhassa jokaista saamaasi tarjousta ja nähdä sinulle tarjotut lainakorot sekä muut sopimusehdot. Mikäli taas et halua ottaa vastaan yhtäkään sinulle tarjottua lainaa, voit jättää tarjoukset huomioimatta ilman, että sinulle koituu tästä mitään kuluja. Järjestyslainan kilpailutus palvelussamme on aina maksutonta, eikä sido sinua mihinkään.
          </p>
          <h2>
            Hae järjestelylainaa näin
          </h2>
          <p>
            Järjestelylainaa Rahalaitoksen kautta haet seuraavasti:
          </p>
          <ol>
            <li>
              Täytä sivuillamme maksuton hakemus. Valitse tarvitsemasi lainasumma sekä budjettiisi sopiva takaisinmaksuaika. Näet samantien suuntaa-antavan kuukausierän lainallesi.
            </li>
            <li>
              Jatka hakemuksen tekoa täyttämällä henkilötietosi ja lähetä hakemus.
            </li>
            <li>
              Saat hakemuksen täytettyäsi lainapäätöksen siitä, voidaanko sinulle myöntää järjestelylainaa. Jos päätös on positiivinen, alat saamaan lainatarjouksia Oma Rahalaitos -palveluun, johon saat meiltä tunnukset.
            </li>
            <li>
              Vertaile saamiasi tarjouksia Oma Rahalaitos -palvelussa ja valitse niistä sinulle sopivin järjestelylaina.
            </li>
            <li>
              Allekirjoita sopimus ja maksa vanhat velkasi kerralla pois järjestelylainalla. Tämän jälkeen maksat takaisin vain yhtä lainaa, yhtä korkoa ja yksiä lainakuluja.
            </li>
          </ol>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <faq />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2>
            Jäikö sinulle kysyttävää järjestelylainasta?
          </h2>
          <p>
            Mikäli jokin lainahakemuksen täyttämiseen tai järjestelylainaan liittyvä asia askarruttaa, voit aina ottaa yhteyttä asiakaspalveluumme.
          </p>
          <p>
            Saat meidät kiinni niin puhelimitse kuin myös sähköpostin tai verkkosivuiltamme löytyvän yhteydenottolomakkeen avulla. Palvelemme numerossa (09) 2311 3670 maanantaista perjantaihin kello 8.00-16.00. Sähköpostitiedustelut otamme vastaan osoitteeseen asiakaspalvelu@rahalaitos.fi.
          </p>
          <p>
            Jos kysyttävää ei jäänyt, täytä järjestelylainahakemuksemme nyt – näin voit säästää sekä aikaa että rahaa!
          </p>
          <p>
            <b>Lue lisää aiheesta:</b>
          </p>
          <p>
            <nuxt-link to="/yhdista-lainat">Lainojen yhdistäminen.</nuxt-link>
          </p>
          <p>
            <nuxt-link to="/lainan-korko">Lainan korko.</nuxt-link>
          </p>
          <p>
            <nuxt-link to="/kokemuksia">Kokemuksia</nuxt-link> Rahalaitoksen lainapalvelusta.
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'Jarjestelylaina',
  components: {
    LazyHydrate,
    banks: () => import('~/components/layout/banks'),
    faq: () => import('~/components/layout/pages/jarjestelylainaaFaq'),
    loanSteps: () => import('~/components/layout/loanSteps'),
    mainHero: () => import('~/components/layout/mainHero')
  },
  data () {
    return {
      sideBoxes: [
        {
          icon: () => import('~/assets/img/icons/banks.svg?inline'),
          title: 'Järjestelemällä säästät',
          subtitle: 'lainakuluissa'
        },
        {
          icon: () => import('~/assets/img/icons/time.svg?inline'),
          title: 'Nopeasti ja',
          subtitle: 'helposti'
        },
        {
          icon: () => import('~/assets/img/icons/money.svg?inline'),
          title: 'Järjestelylainaa jopa',
          subtitle: '70 000 €'
        }
      ]
    }
  },
  async fetch ({ store }) {
    await store.dispatch('subpage/setPages', [
      {
        title: 'Kilpailuta lainat',
        subtitle: 'Säästät selvää rahaa',
        link: '/kilpailuta-lainat'
      },
      {
        title: 'Lainaa 40 000 euroa',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-40000-euroa'
      },
      {
        title: 'Kilpailuta pohjoismaiset pankit',
        subtitle: 'Vertaile ja katso mistä saat edullisimman lainan',
        link: '/talous/suomalainen-ruotsalainen-vai-norjalainen-pankki',
        image: '/cms/uploads/large_photo_1538332576228_eb5b4c4de6f5_3bdf11ac3a.jpg'
      }
    ] )
  },
  head () {
    return {
      title: 'Järjestelylainaa helposti | Rahalaitos',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Järjestelylainan avulla yhdistät monta pientä lainaa yhdeksi
            ✔️Pienissä lainoissa on yleensä korkeampi korko kuin yhdessä järjestelylainassa
            ✔️Järjestelylaina vähentää myös kuukausittaisia lainanhoitokuluja`
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/jarjestelylaina' }
      ]
    }
  }
}
</script>
