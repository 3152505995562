<template>
  <form-input
    ref="email"
    key="form.email"
    v-validate="'required|email'"
    :error="errors.first('email')"
    :value="value"
    :warning="warning"
    :tooltip="tooltip"
    :is-static="isStatic"
    name="email"
    type="email"
    label="Sähköpostiosoite"
    placeholder="Sähköpostiosoite"
    @change="validate"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  components: {
    formInput: () => import( '~/components/form/input' )
  },
  inject: {
    $validator: '$validator'
  },
  props: {
    value: {
      default: null,
      type: [String, Number]
    },
    tooltip: {
      default: '',
      type: String
    },
    isStatic: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      warning: ''
    }
  },
  methods: {
    async validate ( value ) {
      this.warning = ''
      // Wait for validator
      await this.$nextTick()
      const isValid = !this.errors.first( 'email' )
      if ( isValid ) {
        // Validate that gmail ends with .com
        const isGmailWrong = /.*@gmail\.(?!com$).*/.test( this.value )
        if ( isGmailWrong ) {
          this.warning = 'Tarkoititko gmail.com?'
        }
      }
      // Always emit change event
      this.$emit( 'change', value )
    }
  }
}
</script>
