<script>
import faq from '~/components/layout/faq'

export default {
  name: 'YrityksenrahoitusFAQAccordion',
  extends: faq,
  data () {
    return {
      questions: [
        {
          title: 'Vaatiiko yrityslainan hakeminen vakuuksia tai takauksia?',
          content:
            `Rahalaitoksen välittämissä enintään 250 000 euron yrityslainoissa
            ei tarvita reaalivakuuksia. Lainanhakija toimii kuitenkin
            yrityslainan omavelkaisena takaajana. Suuremmissa summissa voidaan
            myös reaalivakuuksia tarvita joissain tapauksissa.`
        },
        {
          title: 'Onko rahoituksen hakeminen yritykselle maksutonta?',
          content:
            `Yritysrahoituksen hakeminen on täysin maksutonta, eikä se sido
            sinua mihinkään.`
        },
        {
          title: `Miten rahoituspäätös tehdään ja mitkä asiat vaikuttavat
            yrityslainan saamiseen?`,
          content:
            `Rahoitusyhtiöt käsittelevät hakemuksen aina asiakaskohtaisesti.
            Yrityslainatarjouksen saamiseen vaikuttaa positiivisesti mm.
            moitteeton maksuhistoria ja tuloksentekokyky. Helpoin tapa löytää
            paras yrityslainatarjous on täyttää maksuton lainahakemus
            sivuillamme.`
        },
        {
          title: 'Kuka voi hakea yritykselle rahoitusta?',
          content:
            `Yrityksen rahoitusta voi hakea henkilö, jolla on
            nimenkirjoitusoikeus joko yksin tai yhdessä toisen henkilön kanssa
            lainaa hakevassa yhtiössä. Yritysrahoitus haetaan ja myönnetään
            yritykselle.`
        },
        {
          title: 'Millaiset yritykset voivat hakea rahoitusta?',
          content:
            `Yritysrahoitusta voivat hakea suomalaiset kaupparekisterissä olevat
            yritykset, joiden vastuuhenkilöt asuvat Suomessa. yrityksellä ja
            takaajalla tulee olla puhtaat luottotiedot.`
        }
      ]
    }
  }
}
</script>
