<template>
  <div>
    <main-hero
      ref="hero"
      title="Yrityksen rahoitus nopeisiin investointeihin"
      subtitle="Hanki rahoitus yritykselle jopa 8 000 000 euron hankkeisiin nopeasti"
      side-title="Täytä rahoitushakemus tästä!"
      :hero-background="require('~/assets/img/business-hero-bg.jpg')"
      :show-the-girl="false"
      :side-boxes="topPoints"
      :breadcrumb="{'Yrityslainat': '/yrityslainat', 'Yrityksen rahoitus': ''}"
    >
      <lazy-hydrate when-idle>
        <vauraus-application />
      </lazy-hydrate>
    </main-hero>
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Yritykselle rahoitusta pieniin ja suuriin investointeihin.
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <faq />
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2 class="title is-size-3">
            Yritykselle rahoitusta investointeihin
          </h2>
          <p>
            Yritykset tarvitsevat toiminnan laajentamiseen investointeihin ja
            erilaisiin suurempiin hankkeisiin välillä lisärahoitusta
            nopeastikin. Rahalaitoksen kumppanien avulla on mahdollista löytää
            nopeasti yritykselle rahoitusta suurempiinkin hankkeisiin.
          </p>
          <p>
            Palvelumme kautta yritys voi kilpailuttaa muutaman tuhannen euron
            yrityslainat nopeasti ja tehokkaasti, mutta myös suurempiin
            yritysrahoituksen tarpeisiin löytyy ratkaisu kauttamme. Nyt voit
            löytää rahoituksen yritykselle aina viiteen miljoonaan asti.
            Suuremmat yrityslainat vaativat luonnollisesti hieman pidemmän
            prosessin kuin minuuteissa nostettavat muutaman tuhannen euron
            lainat. Kuitenkin kauttamme prosessi isommissakin yritysrahoitus
            hankkeissa hoidetaan hyvin pitkälle digitaalisesti.
          </p>
          <p>
            Useisiin miljooniin nousevissa investoinneissa voidaan tarvita myös
            reaalivakuuksia ja ainakin niistä on hyötyä lainaehtoja
            neuvoteltaessa. Hakuvaiheessa ei kuitenkaan tarvitse vielä kaivaa
            erilaisista liitteitä yrityksen tuloksesta tai tietoa vakuuksista
            valmiiksi. Tarvittaessa mahdolliset liitteet katsotaan kuntoon
            sujuvasti ennen lainan nostamista.
          </p>
          <h3>
            Yritykselle rahoitusta sujuvasti jopa viiteen miljoonaan saakka
          </h3>
          <p>
            Kun yritys tarvitsee rahoitusta satoja tuhansia tai miljoonia euroja
            on prosessi perinteisten pankkien kanssa usein hidas ja kankea.
            Rahalaitoksen kumppaneiden kautta asia hoituu nopeasti ja sujuvasti.
            Silti suuremmat rahoitustarpeet käsitellään yksilöllisesti yrityksen
            tilanne huomioiden. Jos yrityksesi tarvitsee esimerkiksi viisi
            miljoonaa euroa yritysrahoitusta, kannattaa ehdottomasti katsoa
            millaista rahoitusta kumppanimme pystyvät tarjoamaan.
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
      </div>
    </section>
  </div>
</template>

<script>

import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    vaurausApplication: () => import('~/components/businessApplication/vaurausApplication'),
    faq: () => import('~/components/layout/pages/yrityksenrahoitusFaq'),
    loanSteps: () => import('~/components/layout/business/loanSteps'),
    mainHero: () => import('~/components/layout/mainHero'),
    subpages: () => import('~/components/layout/subpages')
  },
  data () {
    return {
      topPoints: [
        {
          icon: () => import('~/assets/img/icons/scale.svg?inline'),
          title: 'Helposti rahoitusta',
          subtitle: 'yritykselle'
        },
        {
          icon: () => import('~/assets/img/icons/time.svg?inline'),
          title: 'Nopeasti ja',
          subtitle: 'ilmaiseksi'
        },
        {
          icon: () => import('~/assets/img/icons/money.svg?inline'),
          title: 'Yritysrahoitusta jopa',
          subtitle: '8 000 000 €'
        }
      ]
    }
  },
  async fetch ({ store }) {
    await store.commit('loanApplication/MERGE_TO_FORM', { overLimit: true })
    await store.dispatch('subpage/setPages', [
      {
        title: 'Kasvurahoitusta',
        subtitle: 'Lainaa kasvuun ja yllättäviin tilanteisiin',
        link: '/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista'
      },
      {
        title: 'Pankista vai netistä?',
        subtitle: 'Vakuudeton yrityslaina netistä vai perinteinen pankkilaina',
        link: '/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia'
      },
      {
        title: 'Korot ja kulut',
        subtitle: 'Miten yrityslainoja on järkevintä vertailla?',
        link: '/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla'
      }
    ])
  },
  head () {
    return {
      title: 'Yrityksen rahoitus - Kilpailuta yrityksesi rahoitus',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Tarvitsetko yrityksellesi rahoitusta? Rahalaitos kilpailuttaa
            rahoituksen puolestasi täysin ilmaiseksi! Meidän avulla löydät
            edullisimman lainan. Hae jo tänään!`
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/yrityksen-rahoitus' }
      ]
    }
  }
}
</script>
