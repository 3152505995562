<template>
  <div>
    <main-hero
      ref="hero"
      title="Kilpailuta yritysrahoitus nopeasti ja ilmaiseksi"
      subtitle="Vertaile yritysrahoitus -vaihtoehtoja ja valitse sopivin"
      side-title="Täytä rahoitushakemus tästä!"
      :hero-background="require('~/assets/img/business-hero-bg.jpg')"
      :show-the-girl="false"
      :side-boxes="topPoints"
      :breadcrumb="{'Yrityslainat': '/yrityslainat', 'Yritysrahoitus': ''}"
    >
      <lazy-hydrate when-idle>
        <business-application />
      </lazy-hydrate>
    </main-hero>
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Löydä yrityksellesi rahoitus ilmaisen kilpailutuspalvelumme avulla.
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks class="business" />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <terms loan-type="business" />
        </lazy-hydrate>
      </div>
    </section>
    <hero-action />
    <section class="section">
      <div class="container">
        <div class="content">
          <h2 class="title has-text-centered is-size-3">
            Yritysrahoitus tukee yrityksen kasvua ja kehitystä
          </h2>
          <p>
            Yritysrahoitus tarkoittaa laajasti ajateltuna yrityksen
            varainhankintaa kokonaisuutena. Se tarkoittaa lainan hankkimisen
            lisäksi esimerkiksi yrityksen rahoituksellisia päätöksiä omistajien
            sekä muiden tahojen yritykseen sijoittamaa rahaa. Yritysrahoituksen
            hankkimisessa tärkein päämäärä on yrityksen markkina-arvon
            kasvattaminen taloudelliset riskit halliten. Käytännössä
            yritysrahoitus tarkoittaa sitä, että yritys hankkii jostain
            vierasta pääomaa liiketoiminnan pyörittämiseen ja kasvattamiseen.
          </p>
          <p>
            Monelle yrittäjälle yritysrahoitus ei ole mieluisinta ja vahvinta
            aluetta yrittämisessä. Onnistunut yritysrahoitus on kuitenkin
            ratkaisevassa roolissa, kun tavoitellaan kasvua yrityksen
            kehittämisen ja investointien kautta.
          </p>
          <h3>
            Yritysrahoitus lainan avulla kannattaa kilpailuttaa
          </h3>
          <p>
            Rahalaitoksen kumppanit tarjoavat lainaa yritysrahoitusta varten
            nopeasti ja joustavasti. Rahalaitos ei itse lainaa rahaa vaan
            välittää yritysrahoitusta tarvitsevien hakemukset useille
            luotettaville rahoituskumppaneille ja pyytää näiltä tarjoukset.
            Yritysrahoituksen hakija voi vertailla saamiaan tarjouksia
            palvelussamme ja valita halutessaan niistä itselleen sopivimman.
          </p>
          <p>
            Yritysrahoituksen kilpailuttaminen Rahalaitoksen palvelussa onnistuu
            ilman reaalivakuuksia ja verkossa luoton hakeminen on helppoa ja
            nopeaa. Lainahakemuksen täyttämiseen tarvitaan yrityksen tiedot ja
            henkilötakaajana toimivan vastuuhenkilön tiedot. Voit kilpailuttaa
            yritysrahoituksen Rahalaitoksella aina ilman vakuuksia. Kun täytät
            hakemuksen, saat nopeasti tarjoukset kumppaneiltamme ja voit tehdä
            vertailun mikä saamistasi lainatarjouksista on yritykselle paras.
          </p>
          <p>
            Yritysrahoituksen vaihtoehtojen vertailu on hyvä keino säästää
            kuluissa. Kilpailuttamalla lainan ja vertaamalla tarjouksia voi
            säästää jopa tuhansia euroja. Palvelumme avulla säästät myös aikaa,
            kun etsimme puolestasi yrityksellesi edullisen lainan. Rahalaitos on
            puolueeton toimija ja meille tärkeintä on löytää lainanhakijoillemme
            paras mahdollinen laina.
          </p>
          <p>
            Lainahakemuksen täyttäminen ja yrityslainan kilpailuttaminen ei sido
            ottamaan lainaa ja se on täysin ilmaista.
          </p>
          <h3>
            Yritysrahoitus nopeasti ja ilman vakuuksia netin kautta
          </h3>
          <p>
            Nopeasti saatavilla oleva rahoitus voi olla merkittävä tekijä
            yrityksen tulevaisuuden kannalta. Joskus esimerkiksi hyvien
            kauppojen kiinnilyöminen vaatii nopeita liikkeitä, ja nopea
            rahoitus voi olla avain liiketoiminnan menestykseen.
          </p>
          <p>
            Rahalaitoksen kautta voit hakea lainaa suoraan netin kautta,
            ilman paperisten lomakkeiden täyttöä ja pankkikonttoreissa
            jonottamista. Moni kumppaninamme toimiva lainantarjoaja antaa
            rahoituspäätöksen suoraan automatisoidun hakuprosessin kautta,
            jolloin lainapäätöksen saa hetkessä. Hyväksytty laina voidaan
            usein maksaa hakijan tilille jo samana päivänä.
          </p>
          <h3>
            Yritysrahoituksen saajan vaatimukset
          </h3>
          <p>
            Yritysrahoitusta voi hakea sekä ennen yrityksen perustamista tai
            toiminnan ollessa jo käynnissä. Yritysten ja lainaa hakevan henkilön
            täytyy kuitenkin täyttää muutamia vaatimuksia pystyäkseen laittamaan
            lainahakemuksen vireille. Tärkein ehto yritysrahoituksen hakemiselle
            on, että yrityksellä tai sen vastuuhenkilöillä ei ole merkintöjä
            luottotiedoissa. Yrityksen vastuuhenkilöiden täytyy lisäksi olla
            hyvämaineisia, eli taustalta ei saa löytyä rikoksia tai muuta
            epäilyttävää. Yrityksen täytyy myös kuulua suomalaiseen
            kaupparekisteriin. Osa yrityslainoja tarjoavista rahoittajista voi
            asettaa rajoituksia lisäksi esimerkiksi hakijan iän ja yrityksen
            perustamisajan suhteen.
          </p>
          <p>
            Eri lainantarjoajat suhtautuvat kelpoisuusehtoihin hieman eri
            tavoin. Sen takia kannattaa kilpailuttaa laina Rahalaitoksella
            ja näin rahoitushakemus toimitetaan kerralla usealle
            lainantarjoajalle. Jos et saa lainaa yhdestä yhtiöstä, voit saada
            positiivisen rahoituspäätöksen toiselta toimijalta.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    banks: () => import( '~/components/layout/business/banks' ),
    businessApplication: () => import( '~/components/businessApplication' ),
    heroAction: () => import( '~/components/layout/heroAction' ),
    loanSteps: () => import( '~/components/layout/business/loanSteps' ),
    mainHero: () => import( '~/components/layout/mainHero' ),
    subpages: () => import( '~/components/layout/subpages' ),
    terms: () => import( '~/components/layout/terms' )
  },
  data () {
    return {
      topPoints: [
        {
          icon: () => import( '~/assets/img/icons/scale.svg?inline' ),
          title: 'Kilpailuta rahoituspalvelut',
          subtitle: 'kattavasti'
        },
        {
          icon: () => import( '~/assets/img/icons/time.svg?inline' ),
          title: 'Nopea ja ilmainen',
          subtitle: 'palvelu'
        },
        {
          icon: () => import( '~/assets/img/icons/money.svg?inline' ),
          title: 'Yritysrahoitus',
          subtitle: 'joustavasti'
        }
      ]
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Kasvurahoitusta',
        subtitle: 'Lainaa kasvuun ja yllättäviin tilanteisiin',
        link: '/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista'
      },
      {
        title: 'Pankista vai netistä?',
        subtitle: 'Vakuudeton yrityslaina netistä vai perinteinen pankkilaina',
        link: '/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia'
      },
      {
        title: 'Korot ja kulut',
        subtitle: 'Miten yrityslainoja on järkevintä vertailla?',
        link: '/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla'
      }
    ] )
  },
  head () {
    return {
      title: 'Yritysrahoitus - Kilpailuta yritysrahoitus | Rahalaitos',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Tarvitsetko yritysrahoitusta? Rahalaitos kilpailuttaa
            yritysrahoituksen puolestasi täysin ilmaiseksi! Meidän avulla löydät
            edullisimman lainan. Hae jo tänään!`
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/yritysrahoitus' }
      ]
    }
  },
  methods: {
    scrollToHero () {
      this.$refs.hero.$el.scrollIntoView( {
        behavior: 'smooth',
        block: 'start'
      } )
    }
  }
}
</script>
