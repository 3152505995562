<script>
import faq from '~/components/layout/faq'

export default {
  name: 'AmountFAQAccordion',
  extends: faq,
  data () {
    const amount = parseInt( this.$route.name.split( '-' )[1] )
    return {
      questions: [
        {
          title: `Kuka voi saada lainaa ${amount} euroa`,
          content:
            `Kumppanimme ovat vastuullisia luotonantajia eivätkä he
              tahdo, että joudut taloudellisiin vaikeuksiin lainaamisen
              takia. Tästä syystä sinun täytyy täyttää seuraavat ehdot,
              jotta voit saada lainaa ${amount} euroa:<br><br>- Sinulla on
              yli 600 € kuukausitulot<br>- Olet vähintään
              20-vuotias,<br>- Sinulla ei ole julkisia maksuhäiriömerkintöjä.`
        },
        {
          title: `Kuinka nopeasti voin saada lainaa ${amount} euroa?`,
          content:
            `Koska Rahalaitos ei ole luotonmyöntäjä, emme valitettavasti
              voi vaikuttaa siihen koska sinä saat rahat tilille. Me toimitamme
              hakemuksesi sähköisesti suoraan pankeille ja saat luottopäätöksen
              heti täytettyäsi hakemuksen. Useimmat kumppaneistamme lupaavat,
              että parhaimmassa tapauksessa saat rahat tilille jopa saman
              päivän aikana.`
        },
        {
          title: `Voinko hakea lainaa ${amount} euroa maksutta?`,
          content:
            `Rahalaitoksen palvelussa lainan hakeminen on aina täysin
            maksutonta. Et myöskään sitoudu vielä mihinkään, kun täytät
            lainahakemuksen ja haet lainaa palvelussamme. Voit rauhassa
            vertailla saamiasi tarjouksia ja halutessasi nostaa itse
            valitsemasi lainan. `
        },
        {
          title: `Voiko saada ${amount} euroa lainaa ilman säästöjä?`,
          content:
            `Rahalaitoksen kumppanina toimivat lainantarjoajat eivät
            vaadi lainan saamiseen säästöjä tai muita vakuuksia.
            Lainaa voi siis saada vaikka sinulla ei olisi säästöjä.`
        }
      ]
    }
  }
}
</script>
