



















import { defineComponent, ref, useContext, useFetch, useMeta, useRoute } from '@nuxtjs/composition-api'

interface Company {
  type: string
  content: string
  title: string
  metatitle: string
  metadesc: string
}

export default defineComponent( {
  components: {
    sliders: () => import( '~/components/subpage/sliders.vue' ),
    breadcrumb: () => import( '~/components/breadcrumb.vue' )
  },
  layout: 'subpage',
  setup () {
    const route = useRoute()
    const { $axios, error } = useContext()
    const company = ref<Company>( {} as Company )
    const { fetch } = useFetch( async () => {
      const param: string = route.value.params.company
      const strapi: [Company] = await $axios.$get( `/cms/partners?slug=${param}` )
      if ( strapi.length ) {
        company.value = strapi[0] as Company
      }
      else {
        error( { message: `Yhtiötä ${param} ei löydy.`, statusCode: 404, path: '/yhteistyokumppanit/' } )
      }
    } )

    fetch()

    useMeta( () => ( {
      title: company.value.metatitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: company.value.metadesc
        }
      ]
    } ) )

    return {
      company
    }
  },
  head: {},
  computed: {
    breadcrumbPath (): {[key: string]: string} {
      const path: {[key: string]: string} = {}
      if ( this.company.type === 'business' ) {
        path.Yrityslainat = '/yrityslainat'
        path.Yrityslainakumppanit = '/yrityslainakumppanit'
      }
      else {
        path.Lainaa = '/lainaa'
        path.Lainakumppanit = '/lainakumppanit'
      }
      path[this.company.title] = ''
      return path
    }
  }
} )
